
// # 1
// grrrp-gpgpp-yprgp-gyppp-pyryy
// ppppp-ggggg-rrrrr-yyyyy-ppppp


// # 2
// gcrpg-gygpy-cpcrr-rygcc-pypyy
// crrrc-ycrcy-yycyy-ppggg-pppgg


// #3
//ytyycc-ttcryc-tcycty-pytccy-pygrgc-cycycy

module.exports = {

    LEVELS: [
        [
            'gygy-ygyg-gygy-ygyg', // Level 1  
            'gggg-yyyy-gggg-yyyy',
        ], [
            'yyyy-ygry-yrgy-yyyy', // Level 2  
            'yyyy-yrry-yggy-yyyy'
        ],  [
            'ryyr-yyyy-yyyy-ryyr', // Level 3  
            'yyyy-yrry-yrry-yyyy'
        ], [
            'yggg-rrry-yyyy-yyyy', // Level 4  
            'ggyy-gyyy-yyyr-yyrr'
        ], [
            'rrry-yyyy-yyyy-yggg', // Level 5  
            'ryyg-ygyy-yyry-ryyg'
        ],  [
            'yryy-rrry-yyyg-gygy', // Level 6  
            'rgyy-yrgy-yyrg-yyyr'
        ], [
            'grgy-yrgr-ggyg-grgy', // Level 7  
            'ygry-rggg-gggr-yrgy'
        ], [
            'yggy-grrg-grrg-yggy', // Level 8  
            'rggr-gyyg-gyyg-rggr'
        ], [
            'rgyg-ygyr-yggg-ggrr', // Level 9  
            'yggr-gyrg-gryg-rggy'
        ], [
            'ryry-pyyr-ygry-pyyg', // Level 10  
            'ryyr-ypgy-ypgy-ryyr' 
        ], [
            'gyyg-gryp-pryy-yyrp', // Level 11  
            'pppy-yrrr-gggy-yyyy' 
        ], [
            'rryy-rryy-ggpp-ggpp', // Level 12  
            'rryy-rgpy-ggpp-rgpy'
        ], [
            'ryyr-pggp-pggp-yggy', // Level 13  
            'gpgy-pryg-gygp-ygpr'
        ], [
            'rgrg-prpp-ygry-pyyg', // Level 14 
            'ygyg-gygy-prpr-rprp'
        ], [
            'rgrg-ggyg-ppyy-gypp', // Level 15  
            'ggyp-pgyr-pgyr-ggyp'
        ], [
            'yrrg-pryp-ggpr-pygy', // Level 16  
            'rpgy-rpgy-rpgy-rpgy'
        ], [
            'yrcg-yrgc-ygpg-pppy', // Level 17  
            'yyyy-rccr-ggpp-ggpp'
        ], [
            'ggcy-gpcg-pyrc-ccrc', // Level 18  
            'rppr-gccg-cyyc-gccg'
        ], [
            'rrgy-ppcg-gcrc-gyyy', // Level 19  
            'ycgp-rycg-gryc-pgry'
        ], [
            'gcyg-rtyr-ypcg-ptrt', // Level 20  
            'rryy-rpcy-gcpt-ggtt'
        ], [
            'gcgy-tgpr-pgyc-trtt', // Level 21  
            'pttp-tcct-grrg-yggy'
        ],  [
            'ygrc-ntny-pgrg-tpgc', // Level 22  
            'gngn-cgcg-pprr-yytt'
        ],  [
            'cgyg-ggnp-rycr-ttnp', // Level 23  
            'gtyn-tgrp-yrgc-npcg'
        ], [
            'tcng-pity-ipnr-rygc', // Level 24  
            'ttcc-iipp-rrgg-yynn'
        ], [
            'tcng-pity-ipnr-rygc', // Level 25  
            'icpn-cinp-rygt-yrtg'
        ], [
            'grrrp-gpgpp-yprgp-gyppp-pyryy', // Level 26  
            'ppppp-ggggg-rrrrr-yyyyy-ppppp'
        ], [
            'rpryg-prrpg-yggrr-ggpgy-rggyr', // Level 27  
            'grrrg-pgrgy-ppgyy-pgrgy-grrrg'
        ], [
            'prpcp-ccrrp-grgpy-rtyyr-tpgcg', // Level 28  
            'pcccp-ppcpp-rryrr-rygyr-tgggt'
        ],  [
            'ipcgy-irtty-nrgcn-ttcpi-itygr', // Level 29  
            'yyprr-yipri-ggncc-ginci-ttttt'
        ], [
            'yyrypg-rgpygy-yyyyrr-yyprpy-ygyygr-pypyyg', // Level 30  
            'ygyygy-gyggyg-ypyppy-pypyyp-yryrry-ryryyr'
        ],
        [
            'ggcpcp-ptgrpp-ggyypt-prrrgp-yggpyc-rprycy', // Level 31     
            'ypgrct-pypgrc-gpypgr-rgpypg-crgpyp-tcrgpy'
        ],
        [
            'ctcgyr-ciiyit-itrntt-yngytr-yitngc-nyigrt', // Level 32     
            'igitic-gitici-yrynyg-rynygy-tctrtn-ctrtnt'
        ],
        [
            'ypygtgp-ptcgyyg-ycrrrrt-gyccyyg-ytccryp-rptytyc-prtgpyy', // Level 33     
            'ypctgry-ypctgry-ypctgry-ypctgry-ypctgry-ypctgry-ypctgry'
        ],
        [
            'nccnyyy-yprinin-cincntt-cyrrtct-nrcicri-nncniiy-pynincr', // Level 34     
            'yncirtp-nyncirt-cnyncir-icnynci-ricnync-tricnyn-ptricny'
        ],
        [
            'incgntn-cpyyirp-iiynyrn-itnipti-gygryyg-irpyyyc-cyynnty', // Level 35     
            'ittyrri-ittyrri-nggyccn-nggyccn-piiynnp-piiynnp-yyyyyyy'
        ]
    ],
    
    WEEKLY_START_DATE: '2022-06-08',

    WEEKLY_PUZZLES: [
        [
            'rrry-rrry-gggy-gggy',
            'ryry-ygyg-rgrg-grgr'
        ],
        [
            'yyyy-rrrr-yyyy-rrrr', 
            'yyyr-yyyr-rrry-rrry'
        ],
        [
            'rgrg-ggyg-ppyy-gypp', 
            'ggrg-ypyp-ggpr-pygy'
        ],
        [
            'rgrg-prpp-ygry-pyyg', 
            'pgyr-pgpy-grpr-yryg'
        ],
        [
            'ytyr-nrpp-icig-cngt', 
            'ttyi-pcnn-rrgi-pcgy'
        ],
        [
            'ggyct-rptyg-tcpcy-gpptt-pygyr',
            'gggyy-yyygg-tttpp-ppptt-cccrr'
        ],
        [
            'gyyyyy-pccyyr-ppgpyr-pggcyr-pggrcy-yryccr',
            'ppyypp-pryyrp-ggyygg-gryyrg-ccyycc-cryyrc'
        ],
        [
            'rcpprgr-cycpcrr-ygprppg-ypyygrc-ygcgcyp-prpgcgc-pgyrppy',
            'yyyprrr-yyyprrr-yyyprrr-ppppppp-gggpccc-gggpccc-gggpccc'
        ],
        [
            'ninc-ttyc-pgyp-yyig',
            'ggyy-iicc-nnpp-yytt'
        ],
        [
            'ggggppp-gpgppgp-gppgpgg-ppgpppp-ppppggp-pggpggg-ppppgpg',
            'ppppppp-ggggggg-ppppppp-ggggggg-ppppppp-ggggggg-ppppppp'
        ],
        [
            'npny-cygg-cypn-cgcp',
            'pccg-ppgg-yynn-cync'
        ],
        [
            'prpyyy-pppgpy-rgypyg-ygypgr-ggryry-ryrggp',
            'yygrpp-gyygrp-rgyygr-prgyyg-pprgyy-ppprgy'
        ],
        [
            'prpyyy-pppgpy-rgypyg-ygypgr-ggryry-ryrggp',
            'yygrpp-gyygrp-rgyygr-prgyyg-pprgyy-ppprgy'
        ],
        [
            'yprpy-yygpr-yyryg-gpyyg-pyygy',
            'gygyg-ygygy-pypyp-ypypy-ryryr'
        ],
        [
            'ntiytit-cnyccyi-pnrcrnt-rgcytgt-tigpriy-ngtrgyy-ggiicct',
            'yrrrrry-gyyyyyg-tgggggt-ptttttp-innnnni-tiiiiit-ccccccc'
        ],
        [
            'rtrpy-rptry-tytry-pcyct-cppcc',
            'crypt-crypt-crypt-crypt-crypt'
        ],
        [
            'grgggy-grrrrr-rgyggg-grggrg-gggrgy-rgrgyg',
            'gggggg-grrrrg-gryyrg-gryyrg-grrrrg-gggggg'
        ],
        [
            'prcyrgrc-pcrrrycg-ypprgcgr-cgryyccc-yrpprggy-yrgrpcyg-ccccrrrg-cprggycr',
            'prrccrrg-yprccrgr-ygpccgyr-ygpccgyr-ygpccgyr-ygpccgyr-yprccrgr-prrccrrg'
        ]
    ]
};

