"use strict";

const { Howl, Howler } = require("howler");
const ejs = require("./ejs");

const dayjs = require("dayjs");
const keycode = require("keycode");
const { default: hotkeys } = require("hotkeys-js");
const ClipboardJS = require("clipboard");

const puzzleBuilder = require("./puzzle-builder");

const DEFAULT_CONTROLS = {
	w: "MOVE_ROW_UP",
	a: "MOVE_COL_LEFT",
	s: "MOVE_ROW_DOWN",
	d: "MOVE_COL_RIGHT",

	up: "SHIFT_UP",
	down: "SHIFT_DOWN",
	right: "SHIFT_RIGHT",
	left: "SHIFT_LEFT",
};

const { LEVELS, WEEKLY_PUZZLES, WEEKLY_START_DATE } = require("./puzzles");

// <Utlities>
const Q = document.querySelector.bind(document);
const QA = document.querySelectorAll.bind(document);

const AddListernerToElements = (elArr, event, listener) => {
	Array.from(elArr).map((el) => el.addEventListener(event, listener));
};

const swap = (json) => {
	const ret = {};
	for (var key in json) {
		ret[json[key]] = key;
	}
	return ret;
};

const objectHasKeys = (obj) => {
	return Object.keys(obj).length;
};
const throttle = (callback, limit) => {
	var waiting = false; // Initially, we're not waiting
	return function () {
		// We return a throttled function
		if (!waiting) {
			// If we're not waiting
			callback.apply(this, arguments); // Execute users function
			waiting = true; // Prevent future invocations
			setTimeout(function () {
				// After a period of time
				waiting = false; // And allow future invocations
			}, limit);
		}
	};
};

function chunk(array, count) {
	if (count == null || count < 1) return [];
	var result = [];
	var i = 0,
		length = array.length;
	while (i < length) {
		result.push(Array.prototype.slice.call(array, i, (i += count)));
	}
	return result;
}

function loopVar(value, min, max) {
	if (value > max) {
		value = min;
	}

	if (value < min) {
		value = max;
	}

	return value;
}

function formatTime(seconds, type) {
	const m = Math.floor(seconds / 60);
	const s = Math.round(seconds % 60);

	if (type === "string") {
		return (m > 9 ? m : "0" + m) + "m" + " " + (s > 9 ? s : "0" + s) + "s";
	} else {
		return { m: m > 9 ? m : "0" + m, s: s > 9 ? s : "0" + s };
	}
}

function formatMoves(moves) {
	return moves > 9 ? moves : "0" + moves;
}

function formatTimeAndMoves() {
	const seconds = Timer.secElapsed;
	const moves = MovesTracker.moves;

	const { m, s } = formatTime(seconds);
	const formattedMoves = formatMoves(moves);

	return ejs.render(Q("#time-elapsed").innerHTML, {
		mins: m,
		secs: s,
		moves: formattedMoves,
	});
}

function shiftRow(matrix, row, direction, rowCount = 4) {
	const newMatrix = [];

	matrix.forEach((_row, i) => {
		if (i === row - 1) {
			const newRow = [];
			for (let j = 0; j < _row.length; j++) {
				const next = _row[loopVar(j - direction, 0, rowCount - 1)];
				newRow.push(next);
			}
			newMatrix.push(newRow);
		} else {
			newMatrix.push(_row);
		}
	});

	return newMatrix;
}
const transpose = (array) => {
	return array[0].map((_, colIndex) => array.map((row) => row[colIndex]));
};

function shiftCol(matrix, col, direction, rowCount = 4) {
	const tempMatrix = transpose(matrix);
	const shiftMatrix = shiftRow(tempMatrix, col, direction, rowCount);

	return transpose(shiftMatrix);
}

// </Utlities>

// <HelperObj>
const Timer = {
	secElapsed: 0,
	_interval: 0,
	_hasInited: false,

	init() {
		if (this._hasInited) {
			return;
		}
		this._hasInited = true;

		this.resume();
	},

	resume() {
		this._interval = setInterval(() => {
			this.secElapsed += 1;
			this.set();
		}, 1000);
	},

	stop() {
		this.secElapsed = 0;
		this._hasInited = false;

		clearInterval(this._interval);
	},

	set() {
		Array.from(QA("[data-timer]")).map(
			(el) => (el.innerHTML = formatTimeAndMoves()),
		);
	},

	pause() {
		clearInterval(this._interval);
	},
};

const MovesTracker = {
	moves: 0,

	incr() {
		this.moves++;
		this.set();
	},

	set() {
		Array.from(QA("[data-timer]")).map(
			(el) => (el.innerHTML = formatTimeAndMoves()),
		);
	},

	reset() {
		this.moves = 0;
	},
};

const LocalDB = {
	scores: {},
	moves: {},

	_saveItem(key, value) {
		const json = JSON.stringify(value);
		localStorage.setItem(key, json);
	},

	_loadItem(key) {
		const json = localStorage.getItem(key) || "{}";
		return JSON.parse(json);
	},

	setMeta(key, value) {
		this.meta[key] = value;

		this.saveMeta();
	},

	getMeta(key) {
		return this.meta[key];
	},

	incrMeta(key) {
		if (this.meta[key]) {
			this.meta[key]++;
		} else {
			this.meta[key] = 1;
		}

		this.saveMeta();

		return this.meta[key];
	},

	init() {
		this.scores = this._loadItem("scores");
		this.moves = this._loadItem("moves");
		this.meta = this._loadItem("meta");

		this.renderScores();
	},

	saveMeta() {
		this._saveItem("meta", this.meta);
	},

	selectedPuzzleType: "levels",

	renderScores() {
		const daysSinceDailyStart = Math.floor(
			dayjs().diff(WEEKLY_START_DATE) / (24 * 60 * 60 * 1000) / 7,
		);
		const LEVELSARR =
			this.selectedPuzzleType === "weekly"
				? WEEKLY_PUZZLES.slice(0, daysSinceDailyStart + 1)
				: LEVELS;

		const scores = [];

		for (const [index, level] of LEVELSARR.entries()) {
			const moves = Number.isInteger(
				this.getMoves(index, this.selectedPuzzleType),
			)
				? this.getMoves(index, this.selectedPuzzleType)
				: 0;
			const time = Number.isInteger(
				this.getScore(index, this.selectedPuzzleType),
			)
				? this.getScore(index, this.selectedPuzzleType)
				: 0;

			scores.push({
				id: index,

				moves,
				time,

				bestMoves: Number.isInteger(
					this.getMoves(index, this.selectedPuzzleType),
				)
					? formatMoves(this.getMoves(index, this.selectedPuzzleType))
					: null,
				bestTime: Number.isInteger(
					this.getScore(index, this.selectedPuzzleType),
				)
					? formatTime(this.getScore(index, this.selectedPuzzleType), "string")
					: null,
			});
		}

		Q("#high-scores-body").innerHTML = ejs.render(Q("#high-scores").innerHTML, {
			puzzleType: this.selectedPuzzleType,
			scores,
		});
	},

	getScore(level, type) {
		if (type === "levels") {
			return this.scores[String(level)];
		} else {
			return this.scores[level + type];
		}
	},

	getMoves(level, type) {
		if (type === "levels") {
			return this.moves[String(level)];
		} else {
			return this.moves[level + type];
		}
	},

	setMoves(level, type, moves) {
		if (moves < (this.getMoves(level, type) || Number.MAX_SAFE_INTEGER)) {
			if (type === "levels") {
				this.moves[String(level)] = moves;
			} else {
				this.moves[level + type] = moves;
			}
		}

		this._saveItem("moves", this.moves);
		this.renderScores();
	},

	setScore(level, type, timeElapsed) {
		if (timeElapsed < (this.getScore(level, type) || Number.MAX_SAFE_INTEGER)) {
			if (type === "levels") {
				this.scores[String(level)] = timeElapsed;
			} else {
				this.scores[level + type] = timeElapsed;
			}
		}

		this._saveItem("scores", this.scores);
		this.renderScores();
	},
};

const Modal = {
	show(name) {
		setTimeout(() => {
			Game.canAcceptInput = false;
		}, 100);

		Q("#base-modal").classList.replace("-z-10", "z-10");

		Q("#base-modal").style.opacity = 1;

		Array.from(QA("#base-modal > [data-modal-body]")).map((el) =>
			el.classList.add("hidden"),
		);

		Q(`[data-modal-body="${name}"]`).classList.replace("hidden", "block");

		Popover.hide();
	},

	hide() {
		Game.canAcceptInput = true;

		Q("#base-modal").style.opacity = 0;

		setTimeout(() => Q("#base-modal").classList.replace("z-10", "-z-10"), 300);
	},
};

const Popover = {
	isVisible: false,

	show() {
		this.isVisible = true;

		Game.canAcceptInput = false;

		Q("#popover").classList.replace("-z-10", "z-10");
		Q("#popover").style.opacity = 1;
	},

	hide() {
		this.isVisible = false;

		Game.canAcceptInput = true;

		Q("#popover").style.opacity = 0;
		setTimeout(() => Q("#popover").classList.replace("z-10", "-z-10"), 300);
	},
};

const Controls = {
	controls: {},

	init() {
		const localDbControls = LocalDB._loadItem("controls");
		this.controls = objectHasKeys(localDbControls)
			? LocalDB._loadItem("controls")
			: DEFAULT_CONTROLS;

		const swappedControls = swap(this.controls);

		for (const [key, value] of Object.entries(swappedControls)) {
			Q(`[data-key-input='${key}']`).value = value;
		}
	},

	saveControls(controls) {
		this.controls = controls;

		LocalDB._saveItem("controls", this.controls);
	},
};
// </HelperObj>

// <Sounds>
let clickSoundArrows, clickSoundZX, success;

function assignSounds() {
	clickSoundArrows = new Howl({
		src: ["/assets/sounds/click.mp3"],
	});

	clickSoundZX = new Howl({
		src: ["/assets/sounds/click2.wav"],
		volume: 0.1,
	});

	success = new Howl({
		src: ["/assets/sounds/success.mp3"],
		volume: 0.2,
	});
}
const containerEl = document.getElementById("container");
const containerTargetEl = document.getElementById("containerTarget");

assignSounds();
// </Sounds>

const Game = {
	rows: 4,
	moves: 0,

	cubeWidth: null,
	previewCubeWidth: null,

	soundEnabled: true,

	arrangement: "",
	finalArrangement: "",

	currentLevel: "",

	canAcceptInput: false,

	puzzleType: "levels",

	initGame() {
		const url = new URL(window.location);
		const level = url.searchParams.get("level");
		const type = url.searchParams.get("type");

		LocalDB.init();

		this.soundEnabled =
			LocalDB.getMeta("sound_enabled") != null
				? LocalDB.getMeta("sound_enabled")
				: true;

		if (this.soundEnabled) {
			Q("#sound").checked = true;
		}

		this.currentLevel = level
			? Number(level - 1)
			: this.getUnfinishedLevel() || 0;

		this.puzzleType = type || "levels";
		this.setGame();

		Controls.init();
	},

	showPuzzleOfTheWeek() {
		this.currentLevel = Math.floor(
			dayjs().diff(WEEKLY_START_DATE) / (24 * 60 * 60 * 1000) / 7,
		);
		this.puzzleType = "weekly";

		this.setGame();
	},

	initArrangement() {
		const arrangementSplit = this.arrangement.split("");
		const finalArrangementSplit = this.finalArrangement.split("");

		const baseWidth =
			window.innerWidth < 768
				? window.innerWidth / 4.3
				: window.innerHeight / 11;

		this.rows = Math.ceil(Math.sqrt(arrangementSplit.length));
		this.cubeWidth = Math.floor(baseWidth / (this.rows / 4));
		this.previewCubeWidth =
			window.innerWidth < 768 ? this.cubeWidth / 3 : this.cubeWidth / 2;

		document.body.style.setProperty("--rows", this.rows);

		document.body.style.setProperty("--left", 0);
		document.body.style.setProperty("--top", 0);

		document.body.style.setProperty("--cube-width", this.cubeWidth);
		document.body.style.setProperty(
			"--preview-cube-width",
			this.previewCubeWidth,
		);

		for (let i = 0; i < arrangementSplit.length; i++) {
			const el = document.createElement("div");

			const col = i % this.rows;
			const row = Math.floor(i / this.rows);

			const posX = col * this.cubeWidth;
			const posY = row * this.cubeWidth;

			el.style.top = `calc(50% - ${this.cubeWidth / 2}px)`;
			el.style.left = `calc(50% - ${this.cubeWidth / 2}px)`;

			setTimeout(() => {
				el.style.top = `${posY}px`;
				el.style.left = `${posX}px`;
			}, 300);

			el.setAttribute("data-col", col + 1);
			el.setAttribute("data-row", row + 1);

			el.setAttribute("data-x", posX);
			el.setAttribute("data-y", posY);

			el.setAttribute("data-color", arrangementSplit[i]);

			containerEl.appendChild(el);
		}

		for (let i = 0; i < finalArrangementSplit.length; i++) {
			const el = document.createElement("div");

			const col = i % this.rows;
			const row = Math.floor(i / this.rows);

			const posX = col * this.previewCubeWidth;
			const posY = row * this.previewCubeWidth;

			el.style.top = `calc(50% - ${this.cubeWidth / 2}px)`;
			el.style.left = `calc(50% - ${this.cubeWidth / 2}px)`;

			setTimeout(() => {
				el.style.top = `${posY}px`;
				el.style.left = `${posX}px`;
			}, 300);

			el.setAttribute("data-color", finalArrangementSplit[i]);

			containerTargetEl.appendChild(el);
		}
	},

	playGame(id, type) {
		this.currentLevel = Number(id);
		this.puzzleType = type;
		this.setGame();

		Modal.hide();
	},

	setGame({ shouldReset } = {}) {
		const LEVELSARR = this.puzzleType === "weekly" ? WEEKLY_PUZZLES : LEVELS;

		const url = new URL(window.location);

		url.searchParams.set("level", this.currentLevel + 1);

		if (this.puzzleType === "weekly") {
			url.searchParams.set("type", this.puzzleType);
		} else {
			url.searchParams.delete("type");
		}

		window.history.replaceState({}, "", url);

		this.canAcceptInput = true;

		if (!LEVELSARR[this.currentLevel]) {
			alert("This level does not exist.");
			return;
		}

		if (shouldReset) {
			this.arrangement = LEVELSARR[this.currentLevel][0].replace(/-/g, "");

			LocalDB.setMeta("arrangement" + this.puzzleType + this.currentLevel, "");
			LocalDB.setMeta("secElapsed" + this.puzzleType + this.currentLevel, 0);
			LocalDB.setMeta("moves" + this.puzzleType + this.currentLevel, 0);

			Timer.stop();
			MovesTracker.reset();

			Timer.set();
			MovesTracker.set();
		} else {
			this.arrangement =
				LocalDB.getMeta("arrangement" + this.puzzleType + this.currentLevel) ||
				LEVELSARR[this.currentLevel][0].replace(/-/g, "");

			Timer.secElapsed =
				LocalDB.getMeta("secElapsed" + this.puzzleType + this.currentLevel) ||
				0;
			MovesTracker.moves =
				LocalDB.getMeta("moves" + this.puzzleType + this.currentLevel) || 0;
		}

		this.finalArrangement = LEVELSARR[this.currentLevel][1].replace(/-/g, "");

		const dataForTmpl = {
			level: this.currentLevel,
			completed: false,
			puzzleType: this.puzzleType,
		};

		if (LocalDB.getScore(this.currentLevel, this.puzzleType)) {
			dataForTmpl.completed = true;
		}

		Q("[data-puzzle-header]").innerHTML = ejs.render(
			Q("#puzzle-header").innerHTML,
			dataForTmpl,
		);

		containerEl.innerHTML = "";
		containerTargetEl.innerHTML = "";

		this.initArrangement();
	},

	getNextLevel() {
		const nextIndex = this.currentLevel + 1;

		if (LEVELS[nextIndex]) {
			return nextIndex;
		}
	},

	getUnfinishedLevel() {
		const levelsCrossed = Object.keys(LocalDB.scores);
		const allLevels = Object.keys(LEVELS);

		let difference = allLevels.filter((x) => !levelsCrossed.includes(x));

		return Number(difference[0]);
	},

	setNextGame() {
		this.currentLevel = this.getNextLevel();

		if (!this.currentLevel) {
			Modal.show("allCompleted");
			this.currentLevel = 0;
		}

		this.setGame();
	},

	gameWon() {
		const secElapsed = Timer.secElapsed;
		const moves = MovesTracker.moves;

		LocalDB.setMeta("arrangement" + this.puzzleType + this.currentLevel, null);

		LocalDB.setScore(this.currentLevel, this.puzzleType, secElapsed);
		LocalDB.setMoves(this.currentLevel, this.puzzleType, moves);

		LocalDB.setMeta("secElapsed" + this.puzzleType + this.currentLevel, 0);
		LocalDB.setMeta("moves" + this.puzzleType + this.currentLevel, 0);

		Timer.stop();
		MovesTracker.reset();

		if (this.soundEnabled) {
			success.play();
		}

		if (this.puzzleType === "weekly") {
			Modal.show("weeklyPuzzleCompleted");
			Q("#share-btn-weekly").setAttribute(
				"data-clipboard-text",
				`Eight Colors - Random Puzzle #${this.currentLevel + 1}

Completed in: ${formatTime(secElapsed, "string")}

Give it a try: https://eightcolors.net/?level=${this.currentLevel + 1}&type=weekly`,
			);
			return;
		} else {
			Q("#share-btn-levels").setAttribute(
				"data-clipboard-text",
				`Eight Colors - Level #${this.currentLevel + 1}

Completed in: ${formatTime(secElapsed, "string")}

Give it a try: https://eightcolors.net/?level=${this.currentLevel + 1}`,
			);
		}

		if (!this.getNextLevel()) {
			Modal.show("allCompleted");
		} else {
			Modal.show("gameWon");
			Q("[data-next]").focus();
		}
	},

	shiftColRow(col, row) {
		let direction;

		if (col) {
			direction = col < 0 ? -1 : 1;
			col = Math.abs(col);
		} else {
			direction = row < 0 ? -1 : 1;
			row = Math.abs(row);
		}

		let splitArr = chunk(this.arrangement.split(""), this.rows);

		MovesTracker.incr();

		if (col) {
			splitArr = shiftCol(splitArr, col, direction, this.rows);
		} else {
			splitArr = shiftRow(splitArr, row, direction, this.rows);
		}

		this.arrangement = splitArr.flat().join("");

		LocalDB.setMeta(
			"arrangement" + this.puzzleType + this.currentLevel,
			this.arrangement,
		);
		LocalDB.setMeta(
			"secElapsed" + this.puzzleType + this.currentLevel,
			Timer.secElapsed,
		);

		LocalDB.setMeta(
			"moves" + this.puzzleType + this.currentLevel,
			MovesTracker.moves,
		);

		if (this.arrangement === this.finalArrangement) {
			this.gameWon();
		}

		const elements = Array.from(containerEl.children).filter((el) => {
			if (col) {
				return Number(el.getAttribute("data-col")) === col;
			} else {
				return Number(el.getAttribute("data-row")) === row;
			}
		});

		for (let i = 0; i < elements.length; i++) {
			const current = Number(
				elements[i].getAttribute(col ? "data-row" : "data-col"),
			);

			let dataX = Number(elements[i].getAttribute("data-x"));
			let dataY = Number(elements[i].getAttribute("data-y"));

			if (col) {
				dataY += this.cubeWidth * direction;
			} else {
				dataX += this.cubeWidth * direction;
			}

			const transitionFromTo = (x1, y1, x2, y2) => {
				elements[i].classList.add("notransition");
				elements[i].style.left = `${x1}px`;
				elements[i].style.top = `${y1}px`;

				setTimeout(() => {
					elements[i].classList.remove("notransition");
					elements[i].style.left = `${x2}px`;
					elements[i].style.top = `${y2}px`;
				}, 100);
			};

			if (col) {
				if (dataY >= this.cubeWidth * this.rows) {
					dataY = 0;
					transitionFromTo(dataX, -this.cubeWidth, dataX, dataY);
				} else if (dataY < 0) {
					dataY = this.cubeWidth * this.rows - this.cubeWidth;
					transitionFromTo(dataX, dataY + this.cubeWidth, dataX, dataY);
				} else {
					elements[i].style.left = `${dataX}px`;
					elements[i].style.top = `${dataY}px`;
				}
			} else if (row) {
				if (dataX >= this.cubeWidth * this.rows) {
					dataX = 0;
					transitionFromTo(-this.cubeWidth, dataY, 0, dataY);
				} else if (dataX < 0) {
					dataX = this.cubeWidth * this.rows - this.cubeWidth;
					transitionFromTo(dataX + this.cubeWidth, dataY, dataX, dataY);
				} else {
					elements[i].style.left = `${dataX}px`;
					elements[i].style.top = `${dataY}px`;
				}
			}

			elements[i].setAttribute("data-x", dataX);
			elements[i].setAttribute("data-y", dataY);

			const next = loopVar(current + direction, 1, this.rows);

			elements[i].setAttribute(col ? "data-row" : "data-col", next);
		}
	},

	leftA: 1,
	topA: 1,

	throttledHandleInstructions: throttle(function (instruction, arg) {
		this.handleInstructions(instruction, arg);
	}, 100),

	instructionStore: [],

	handleInstructions(instruction, arg) {
		Timer.init();

		switch (instruction) {
			case "MOVE_COL_RIGHT":
				if (Game.soundEnabled) clickSoundZX.play();
				this.leftA = loopVar(this.leftA + 1, 1, this.rows);
				containerEl.style.setProperty(
					"--left",
					(this.leftA - 1) * this.cubeWidth,
				);
				break;
			case "MOVE_ROW_DOWN":
				if (Game.soundEnabled) clickSoundZX.play();
				this.topA = loopVar(this.topA + 1, 1, this.rows);
				containerEl.style.setProperty(
					"--top",
					(this.topA - 1) * this.cubeWidth,
				);
				break;
			case "MOVE_COL_LEFT":
				if (Game.soundEnabled) clickSoundZX.play();
				this.leftA = loopVar(this.leftA - 1, 1, this.rows);
				containerEl.style.setProperty(
					"--left",
					(this.leftA - 1) * this.cubeWidth,
				);
				break;
			case "MOVE_ROW_UP":
				if (Game.soundEnabled) clickSoundZX.play();
				this.topA = loopVar(this.topA - 1, 1, this.rows);
				containerEl.style.setProperty(
					"--top",
					(this.topA - 1) * this.cubeWidth,
				);
				break;
			case "SHIFT_UP":
				this.instructionStore.push(["SHIFT_UP", arg || -this.leftA]);
				if (Game.soundEnabled) clickSoundArrows.play();
				this.shiftColRow(arg || -this.leftA, null);
				break;
			case "SHIFT_DOWN":
				this.instructionStore.push(["SHIFT_DOWN", arg || -this.leftA]);
				if (Game.soundEnabled) clickSoundArrows.play();
				this.shiftColRow(arg || this.leftA, null);
				break;
			case "SHIFT_RIGHT":
				this.instructionStore.push(["SHIFT_RIGHT", arg || this.topA]);
				if (Game.soundEnabled) clickSoundArrows.play();
				this.shiftColRow(null, arg || this.topA);
				break;
			case "SHIFT_LEFT":
				this.instructionStore.push(["SHIFT_LEFT", arg || -this.topA]);
				if (Game.soundEnabled) clickSoundArrows.play();
				this.shiftColRow(null, arg || -this.topA);
				break;
		}
	},
};

Game.initGame();

const replayEl = Q("[data-replay]");

AddListernerToElements(QA("button[name='reset']"), "click", () => {
	if (confirm("Are you sure you want reset the game?")) {
		Game.setGame({ shouldReset: true });
	}
});

Q("[data-next]").addEventListener("click", () => {
	const spinnerEl = Q("[data-next]").querySelector("#spinner");

	spinnerEl.classList.remove("hidden");
	Q("[data-next]").classList.add("opacity-50");

	// Some delay to make it more explicit that a new level
	// has been loaded
	setTimeout(() => {
		Modal.hide();

		Timer.set();
		Game.setNextGame();

		spinnerEl.classList.add("hidden");
		Q("[data-next]").classList.remove("opacity-50");
	}, 500);
});

replayEl.addEventListener("click", () => {
	Timer.set();
	Game.setGame();
	Modal.hide();
});

window.addEventListener("click", (e) => {
	const targetModal = e.target.getAttribute("data-show-modal");
	const hideModal = e.target.getAttribute("data-hide-modal");

	const level = e.target.getAttribute("data-play");

	const tab = e.target.getAttribute("data-tab");

	if (tab) {
		LocalDB.selectedPuzzleType = tab;
		LocalDB.renderScores();
	}

	if (level) {
		e.preventDefault();
		Game.playGame(level, "levels");
	}

	if (targetModal) {
		Modal.show(targetModal);
	}

	if (hideModal) {
		Modal.hide();
	}
});

let first = [],
	last = [],
	touchedRow,
	touchedCol;

document.body.addEventListener("touchmove", (e) => {
	e.preventDefault();
});

const handleTouchStart = (clientX, clientY) => {
	if (!Game.canAcceptInput) {
		return false;
	}

	const boundingRect = containerEl.getBoundingClientRect();

	first = [clientX, clientY];

	touchedCol = Math.floor((clientX - boundingRect.x) / Game.cubeWidth) + 1;
	touchedRow = Math.floor((clientY - boundingRect.y) / Game.cubeWidth) + 1;

	if (
		touchedCol >= 1 &&
		touchedCol <= Game.rows &&
		touchedRow >= 1 &&
		touchedRow <= Game.rows
	) {
		Game.leftA = loopVar(touchedCol, 1, Game.rows);
		containerEl.style.setProperty("--left", (touchedCol - 1) * Game.cubeWidth);

		Game.topA = loopVar(touchedRow, 1, Game.rows);
		containerEl.style.setProperty("--top", (touchedRow - 1) * Game.cubeWidth);
	}
};

const handleTouchEnd = (clientX, clientY) => {
	if (
		touchedCol > Game.rows ||
		touchedCol < 1 ||
		touchedRow > Game.rows ||
		touchedRow < 1
	) {
		return;
	}

	if (!first.length) {
		return;
	}

	last = [clientX, clientY];

	const deltaX = Math.abs(last[0] - first[0]);
	const deltaY = Math.abs(last[1] - first[1]);

	let direction;

	// Ignore accidental or minor swipes
	if (deltaY < 5 && deltaX < 5) {
		return;
	}

	if (deltaY > deltaX) {
		direction = last[1] > first[1] ? TOP_BOTTOM : BOTTOM_TOP;
	} else {
		direction = last[0] > first[0] ? LEFT_RIGHT : RIGHT_LEFT;
	}

	Timer.init();

	switch (direction) {
		case BOTTOM_TOP:
			Game.throttledHandleInstructions("SHIFT_UP", -touchedCol);
			break;
		case TOP_BOTTOM:
			Game.throttledHandleInstructions("SHIFT_DOWN", touchedCol);
			break;
		case LEFT_RIGHT:
			Game.throttledHandleInstructions("SHIFT_LEFT", touchedRow);
			break;
		case RIGHT_LEFT:
			Game.throttledHandleInstructions("SHIFT_RIGHT", -touchedRow);
			break;
	}
};

containerEl.addEventListener("touchstart", (e) => {
	if (e.touches.length > 1) {
		first = [];
		touchedCol = touchedRow = null;
	}

	const { clientX, clientY } = e.touches[0];

	handleTouchStart(clientX, clientY);
});

containerEl.addEventListener("touchend", (e) => {
	const { clientX, clientY } = e.changedTouches[0];

	handleTouchEnd(clientX, clientY);
});

document.body.addEventListener("mousedown", (e) => {
	const { clientX, clientY } = e;
	handleTouchStart(clientX, clientY);

	if (
		!e
			.composedPath()
			.some(
				(el) =>
					el.nodeType === Node.ELEMENT_NODE &&
					el.getAttribute("data-menu") != null,
			)
	) {
		if (Popover.isVisible) {
			Popover.hide();
		}
	}
});

const TOP_BOTTOM = "TOP_BOTTOM";
const LEFT_RIGHT = "LEFT_RIGHT";
const BOTTOM_TOP = "BOTTOM_TOP";
const RIGHT_LEFT = "RIGHT_LEFT";

// To prevent zooming on iOS
document.addEventListener(
	"dblclick",
	function (event) {
		event.preventDefault();
	},
	{ passive: false },
);

const handler = () => {
	Howler.unload();
	assignSounds();

	document.removeEventListener("touchstart", handler);
};

AddListernerToElements(QA("[data-puzzle-of-the-week]"), "click", () => {
	Game.showPuzzleOfTheWeek();
});

Q("[data-menu]").addEventListener("click", () => {
	if (Popover.isVisible) {
		Popover.hide();
	} else {
		Popover.show();
	}
});

// Hide flicker by hiding main container until the main JS is loaded
Q("#mainContainer").classList.remove("hidden");

// ensure that timer is shown with default values.
Timer.set();

// iOS disabled sound context is it's for sleep more than 30s.
// Hack to restart the sound context if it's been for sleep more than 10s.
let timer = Date.now();
setInterval(() => {
	timer += 100;

	if (Date.now() - timer > 10000) {
		timer = Date.now();

		document.addEventListener("touchstart", handler);
	}
}, 100);

new ClipboardJS(".btn");

AddListernerToElements(QA("[data-clipboard-text]"), "click", (e) => {
	const el = e.target;

	el.classList.add("disabled");
	el.innerHTML = "Text Copied!";

	setTimeout(() => {
		el.classList.remove("disabled");
		el.innerHTML = "Share";
	}, 3000);
});

AddListernerToElements(QA("[data-clipboard-text]"), "click", (e) => {
	const el = e.target;

	el.classList.add("disabled");
	el.innerHTML = "Text Copied!";

	setTimeout(() => {
		el.classList.remove("disabled");
		el.innerHTML = "Share";
	}, 3000);
});

AddListernerToElements(QA("[data-key-input]"), "click", function () {
	this.setSelectionRange(0, this.value.length);
});

Q("[data-save-controls]").addEventListener("click", () => {
	const controls = {};

	Array.from(QA("[data-key-input]")).map((el) => {
		controls[el.value] = el.getAttribute("data-key-input");
	});

	Controls.saveControls(controls);
	Modal.hide();

	alert("Your controls have been saved!");
});

AddListernerToElements(QA("[data-subscribe]"), "submit", async (e) => {
	e.preventDefault();

	const el = e.target;

	const _el = el.querySelector("button[type='submit']");

	_el.classList.add("disabled");
	_el.innerHTML = "Submitting";

	setTimeout(() => {
		_el.classList.remove("disabled");
		_el.innerHTML = "Subscribe";
	}, 3000);

	await fetch("https://formspree.io/f/mqkndrwk", {
		method: "post",
		headers: {
			Accept: "application/json",
		},
		body: JSON.stringify({
			email: el.querySelector("input[type='text']").value,
		}),
	});

	Modal.hide();
});

if (window.location.href.indexOf("pbuilder") !== -1) {
	puzzleBuilder.init({
		Game,
	});
}

Q("#sound").addEventListener("change", (e) => {
	Game.soundEnabled = e.target.checked;
	LocalDB.setMeta("sound_enabled", e.target.checked);
});

hotkeys("*", function (e) {
	const keyCombo = hotkeys
		.getPressedKeyCodes()
		.map((key) => keycode(key))
		.join(" + ");

	if (Game.canAcceptInput) {
		Game.throttledHandleInstructions(Controls.controls[keyCombo]);
	}

	if (e.target.getAttribute("data-key-input")) {
		if (e.key !== "Tab") {
			e.preventDefault();
		} else {
			return;
		}

		if (["Escape", "Backspace", "Delete"].includes(e.key)) {
			e.target.value = "";
		} else {
			e.target.value = keyCombo;
		}
	}
});

hotkeys.filter = function () {
	return true;
};
