String.prototype.shuffle = function () {
    var a = this.split(""),
        n = a.length;

    for(var i = n - 1; i > 0; i--) {
        var j = Math.floor(Math.random() * (i + 1));
        var tmp = a[i];
        a[i] = a[j];
        a[j] = tmp;
    }
    return a.join("");
}

function chunk(array, count) {
    if (count == null || count < 1) return [];
    var result = [];
    var i = 0,
        length = array.length;
    while (i < length) {
        result.push(Array.prototype.slice.call(array, i, i += count));
    }
    return result;
}


const containerEl = document.getElementById('container');
const containerTargetEl = document.getElementById('containerTarget');

module.exports = {
    init({ Game }) {
        let colors = 'ygrpctni';
        let rows;
        
        Game.canAcceptInput = false;

        const yContainer = document.createElement("div");

        const renderArrangement = () => {

            containerEl.innerHTML = '';
            containerTargetEl.innerHTML = '';

            Game.arrangement = Array.from(document.querySelectorAll(".puzzleBox")).map((el) => el.value).join('');
            Game.finalArrangement = Array.from(document.querySelectorAll(".target")).map((el) => el.value).join('');

            document.querySelector("#puzzle").value = chunk(Game.arrangement.split(''), rows).join('-').replace(/,/g, '')
            document.querySelector("#solution").value = chunk(Game.finalArrangement.split(''), rows).join('-').replace(/,/g, '')

            Game.initArrangement();
        };

        yContainer.innerHTML = `
            <div class="mt-2">
            <strong> Rows:</strong> <input type='text' class="border p-1" id='num_rows'/> &nbsp; <strong> CanAcceptInput:</strong>
            
                <input type='checkbox' class="border p-1" id='canAcceptInput' />
                <button id='shufflePuzzle' class="bg-primary text-white p-1 font-semibold rounded">Shuffle</button>
                <button id='shufflePuzzleFromSolution' class="bg-primary text-white p-1 font-semibold rounded">Shuffle From Sol.</button>
                <button id='render' class="bg-primary text-white p-1 font-semibold rounded">Render</button>
            </div>

            <div class="mt-2 flex-between">
                <div>
                    <div id="puzzle-box"></div>
                    <input type='text' id='puzzle' class="mt-2" />
                </div>
                <div>
                    <div id="target"></div>
                    <input type='text' id='solution' class="mt-2" />
                </div>
            </div>
        `

        document.querySelector("#mainContainer").appendChild(yContainer);

        yContainer.addEventListener('click', (e) => {

            if(e.target.getAttribute('id') === 'canAcceptInput') {
                Game.canAcceptInput = e.target.checked;
            }

            if(e.target.getAttribute('id') === 'shufflePuzzle') {
                const howManyColors = Number(prompt("How many colors?"));

                if (!howManyColors) {
                    return;
                }
                const colorsToUse = colors.slice(0, howManyColors);

                let currentColors = colorsToUse.repeat(100).shuffle().slice(0, rows * rows);

                Array.from(document.querySelectorAll(".puzzleBox")).forEach((el, index) => {
                    el.value = currentColors[index]
                })

                currentColors = currentColors.shuffle();

                Array.from(document.querySelectorAll(".target")).forEach((el, index) => {
                    el.value = currentColors[index]
                })

                renderArrangement();
            }

            if(e.target.getAttribute('id') === 'render') {

                renderArrangement();
            }

            if(e.target.getAttribute('id') === 'shufflePuzzleFromSolution') {

                const colorsToUse = Array.from(document.querySelectorAll(".target")).map((el) => el.value).join('').shuffle();

                Array.from(document.querySelectorAll(".puzzleBox")).forEach((x, i) => {
                    x.value = colorsToUse[i];
                })

                renderArrangement();
            }
        })

        yContainer.addEventListener('keyup', (e) => {

            if(e.target.getAttribute('id') === 'num_rows') {
                rows = Number(e.target.value);
                let html = '<div class="flex flex-col gap-2">';

                for (let i = 0; i < rows; i++) {
                    html += '<div class="flex gap-2">';
                    for (let j = 0; j < rows; j++) {
                        html += '<input type="text" class="border p-1 text-sm puzzleBox" maxlength="1" style="width: 32px; height: 32px"  />'
                    }
                    html += '</div>';
                }

                html += '</div>';

                document.querySelector("#puzzle-box").innerHTML = html;

                html = '<div class="flex flex-col gap-2">';

                for (let i = 0; i < rows; i++) {
                    html += '<div class="flex gap-2">';
                    for (let j = 0; j < rows; j++) {
                        html += '<input type="text" class="border p-1 text-sm target" maxlength="1" style="width: 32px; height: 32px"  />'
                    }
                    html += '</div>';
                }

                html += '</div>';

                document.querySelector("#target").innerHTML = html;
            }

            // if (e.target.classList.contains('puzzleBox') || e.target.classList.contains('target') ) {
            //     renderArrangement();
            // }

        });


    }
}